import logo from "../logo.png";

function WizardTitleHeader() {
  return (
    <div className="bg-white md:bg-transparent text-2xl font-bold w-full h-40 uppercase flex flex-col justify-center items-center">
      <img src={logo} alt="buch/nger-w/lhelmi" className="logo" />
    </div>
  );
}

export default WizardTitleHeader;

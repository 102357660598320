import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import countries from "i18n-iso-countries";
import "date-fns";
import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import DateFnsUtils from "@date-io/date-fns";
import * as EmailValidator from "email-validator";

import enNationalities from "../../utils/countries/en/countries.json";
import deNationalities from "../../utils/countries/de/countries.json";
import frNationalities from "../../utils/countries/fr/countries.json";
import esNationalities from "../../utils/countries/es/countries.json";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import PlacesAutocomplete from "../../components/PlacesAutocomplete";
import { FeedbackMessage } from "../../components/FeedbackMessage";
import { scrollToTopHandler } from "../util/scrollToTopHandler";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiPhoneNumber from "material-ui-phone-number";
import { yearsAgoCreator } from "../util/yearsAgoCreator";
import { Link } from "@material-ui/core";
import { useEffect } from "react";
import { saveToCookie } from "../util/cookiesHelper";

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
  es: esLocale,
};
const nationalityMap = {
  en: enNationalities,
  fr: frNationalities,
  de: deNationalities,
  es: esNationalities,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  select: {
    margin: theme.spacing(1),
    width: "100%",
  },

  input: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const extractPersonalInfo = (stepState) => {
  return {
    step: stepState.step,
    gender: stepState.gender,
    title: stepState.title,
    lastName: stepState.lastName,
    firstName: stepState.firstName,
    email: stepState.email,
    birthDate: stepState.birthDate,
    birthPlace: stepState.birthPlace,
    nationality: stepState.nationality,
    job: stepState.job,
  };
};

const extractAddressInfo = (stepState) => {
  return {
    addressPLZ: stepState.addressPLZ,
    addressResidency: stepState.addressResidency,
    addressStreet: stepState.addressStreet,
    addressCountry: stepState.addressCountry,
    contactPhone: stepState.contactPhone,
    contactPhoneAlt: stepState.contactPhoneAlt,
    contactOtherPhoneNumber: stepState.contactOtherPhoneNumber,
    contactFax: stepState.contactFax,
    emergencyContactName: stepState.emergencyContactName,
    emergencyContactPhone: stepState.emergencyContactPhone,
    nationality: stepState.nationality,
  };
};

const eighteenYearsAgoCreator = () => {
  let eighteenYearsAgo = new Date();
  eighteenYearsAgo = eighteenYearsAgo.setFullYear(
    eighteenYearsAgo.getFullYear() - 18
  );
  return eighteenYearsAgo;
};

const extractLanguageInfo = (stepState) => {
  return {
    languageGerman: stepState.languageGerman,
    languageEnglish: stepState.languageEnglish,
    languageFrench: stepState.languageFrench,
    languageSpanish: stepState.languageSpanish,
    languageOther: stepState.languageOther,
    languageOtherDetail: stepState.languageOtherDetail,
    languageIsNotFluent: stepState.languageIsNotFluent,
  };
};

function PersonalInformationStep({ stepState, onSubmit, onGoBack }) {
  const { t, i18n } = useTranslation(["translation", "common"]);
  const [personalInfo, setPersonalInfo] = useState(
    extractPersonalInfo(stepState)
  );
  const [addressInfo, setAddressInfo] = useState(extractAddressInfo(stepState));

  // eslint-disable-next-line no-unused-vars
  const [chosenNationality, setChosenNationality] = useState(
    extractAddressInfo(stepState)
  );
  const [languageInfo, setLanguageInfo] = useState(
    extractLanguageInfo(stepState)
  );
  // eslint-disable-next-line no-unused-vars
  const [countryLocale, setCountryLocale] = useState("DE");

  // eslint-disable-next-line no-unused-vars
  const [country, setCountry] = useState(extractAddressInfo(stepState));

  const [toggleError, setToggleError] = useState(false);
  const [showErrors, setShowErrors] = useState({
    validationPassed: false,
    gender: false,
    title: false,
    lastName: false,
    firstName: false,
    birthDate: false,
    addressStreet: false,
    addressPLZ: false,
    addressResidency: false,
    nationality: false,
    email: false,
    addressCountry: false,
    contactPhone: false,
    language: false,
  });

  useEffect(() => {
    saveToCookie(languageInfo, stepState.step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageInfo]);

  const classes = useStyles();
  countries.registerLocale(
    require(`i18n-iso-countries/langs/${i18n.languages[0] || "de"}.json`)
  );
  const countriesList = countries.getNames(i18n.languages[0] || "de");
  const nationalitiesList = nationalityMap[i18n.languages[0] || "de"];

  const createCountriesList = (list) => {
    const cl = [];
    // eslint-disable-next-line no-unused-vars
    for (const [countryCode, countryName] of Object.entries(list)) {
      cl.push(countryName);
    }
    return cl;
  };

  const getNationalityValue = (nationalitiesList) => {
    return nationalitiesList.filter((country) => {
      return country?.nationality?.translation === addressInfo?.nationality;
    })[0];
  };

  const genders = [
    { value: "male", label: t("steps:personalInformation.male") },
    { value: "female", label: t("steps:personalInformation.female") },
    { value: "other", label: t("steps:personalInformation.other") },
  ];

  const titles = [
    { value: "none", label: t("steps:personalInformation.none") },
    { value: "dr", label: t("steps:personalInformation.dr") },
    { value: "prof", label: t("steps:personalInformation.prof") },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const formState = { ...personalInfo, ...addressInfo, ...languageInfo };
    if (!validateFormHandler(formState)) {
      return;
    }
    onSubmit(formState);
  };

  const stepBackHandler = (event) => {
    event.preventDefault();
    const formState = { ...personalInfo, ...addressInfo, ...languageInfo };
    onGoBack(formState);
  };

  const validateFormHandler = (formState) => {
    let error = { ...showErrors };
    let validationPassed = true;
    const validateField = (key) => {
      if (
        !formState[key] &&
        !key.includes("language") &&
        !key.includes("email") &&
        !key.includes("addressPLZ")
      ) {
        error[key] = true;
        validationPassed = false;
      } else {
        error[key] = false;
      }

      if (key.includes("email")) {
        if (!formState[key] || !EmailValidator.validate(formState[key])) {
          error[key] = true;
          validationPassed = false;
        }
      }
      // if (key.includes("addressPLZ")) {
      //   // regex validates numbers and letters

      //   var regex = /^[a-zA-Z0-9_ ]*$/;
      //   if (!formState[key] || !formState[key].match(regex)) {
      //     error[key] = true;
      //     validationPassed = false;
      //   }
      // }

      if (
        key.includes("language") &&
        !languageInfo.languageSpanish &&
        !languageInfo.languageFrench &&
        !languageInfo.languageEnglish &&
        !languageInfo.languageGerman
      ) {
        error[key] = true;
        validationPassed = false;
      }
    };
    [
      "gender",
      "lastName",
      "firstName",
      "birthDate",
      "addressStreet",
      "addressPLZ",
      "addressResidency",
      "contactPhone",
      "email",
      "nationality",
      "addressCountry",
      "language",
    ].map((key) => validateField(key));
    setShowErrors((state) => ({
      ...state,
      ...error,
    }));

    !validationPassed ? setToggleError(true) : setShowErrors(false);
    return validationPassed;
  };

  return (
    <div className={classes.root}>
      <div id="feedback-message"></div>
      <div className="md:hidden mb-12 flex flex-col items-center">
        <button className="rounded-md px-2 border-solid border-blue-500 justify-center border text-blue-500">
          <Trans i18nKey="common:headings.personalInformation_mobile">
            ( 2 / 5 ) Personal Information
          </Trans>
        </button>
      </div>
      {toggleError && (
        <FeedbackMessage
          error
          heading={
            <Trans i18nKey="common:errors.error_message_heading"></Trans>
          }
          description={
            <Trans i18nKey="common:errors.error_message_description"></Trans>
          }
        />
      )}
      <form
        className="flex flex-col items-center"
        onSubmit={handleSubmit}
        noValidate
      >
        <Grid container spacing={3}>
          <Grid item className={classes.paper}>
            <p className="grey-heading mb-4">
              <Trans i18nKey="steps:personalInformation.stepTopic"></Trans>
            </p>
            <h2 className="font-medium text-4xl text-gray-700 mb-4">
              <Trans i18nKey="steps:personalInformation.stepGreeting"></Trans>
            </h2>
          </Grid>

          <Grid item xs={12}>
            <FeedbackMessage
              heading={<Trans i18nKey="steps:personalInformation.hint"></Trans>}
              description={
                <Trans
                  i18nKey="steps:personalInformation.hintText"
                  components={{ privacyLink: <Link target="_blank" /> }}
                ></Trans>
              }
            />
          </Grid>

          {/* about me section */}
          <Grid item xs={12}>
            <h2 className="blue-heading mt-8">
              <Trans i18nKey="steps:personalInformation.aboutme"></Trans>
            </h2>
          </Grid>
          <Grid item sm={4} xs={12}>
            <p className="font-medium text-md mb-4 text-gray-600">
              <Trans i18nKey="steps:personalInformation.aboutmeIntrotext"></Trans>
            </p>
          </Grid>
          <Grid item sm={8} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  data-cy="gender_dropdown"
                  error={showErrors.gender ? true : false}
                  helperText={
                    showErrors.gender ? t("common:errors.incorrect_entry") : ""
                  }
                  className={classes.select}
                  id="outlined-required"
                  select
                  label={
                    <Trans i18nKey="steps:personalInformation.dropdown1Label"></Trans>
                  }
                  value={personalInfo.gender}
                  onChange={(event) =>
                    setPersonalInfo({
                      ...personalInfo,
                      gender: event.target.value,
                    })
                  }
                  onBlur={() => {
                    saveToCookie(personalInfo, stepState.step);
                  }}
                  variant="outlined"
                >
                  {genders.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={showErrors.title ? true : false}
                  helperText={
                    showErrors.title ? t("common:errors.incorrect_entry") : ""
                  }
                  className={classes.select}
                  id="outlined-required"
                  select
                  label={
                    <Trans i18nKey="steps:personalInformation.dropdown2Label"></Trans>
                  }
                  value={personalInfo.title}
                  onChange={(event) =>
                    setPersonalInfo({
                      ...personalInfo,
                      title: event.target.value,
                    })
                  }
                  onBlur={() => {
                    saveToCookie(personalInfo, stepState.step);
                  }}
                  variant="outlined"
                >
                  {titles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <div>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                data-cy="first_name"
                error={showErrors.firstName ? true : false}
                helperText={
                  showErrors.firstName ? t("common:errors.incorrect_entry") : ""
                }
                className={classes.input}
                id="outlined-required"
                value={personalInfo.firstName}
                onChange={(event) =>
                  setPersonalInfo({
                    ...personalInfo,
                    firstName: event.target.value,
                  })
                }
                onBlur={() => {
                  saveToCookie(personalInfo, stepState.step);
                }}
                label={
                  <Trans i18nKey="steps:personalInformation.firstnameLabel"></Trans>
                }
                placeholder={t(
                  "steps:personalInformation.firstnamePlaceholder"
                )}
                variant="outlined"
              />
            </div>

            <div>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                data-cy="last_name"
                error={showErrors.firstName ? true : false}
                helperText={
                  showErrors.lastName ? t("common:errors.incorrect_entry") : ""
                }
                className={classes.input}
                id="outlined-required"
                value={personalInfo.lastName}
                onChange={(event) =>
                  setPersonalInfo({
                    ...personalInfo,
                    lastName: event.target.value,
                  })
                }
                onBlur={() => {
                  saveToCookie(personalInfo, stepState.step);
                }}
                label={
                  <Trans i18nKey="steps:personalInformation.lastnameLabel"></Trans>
                }
                placeholder={t("steps:personalInformation.lastnamePlaceholder")}
                variant="outlined"
              />
            </div>

            <div>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap[i18n.language]}
              >
                <DatePicker
                  data-cy="date_picker"
                  error={showErrors.birthDate ? true : false}
                  inputVariant="outlined"
                  format="dd.MM.yyyy"
                  margin="normal"
                  className={classes.input}
                  id="outlined-required"
                  maxDate={yearsAgoCreator(16)}
                  disableFuture={true}
                  openTo="year"
                  initialFocusedDate="25.10.1985"
                  label={
                    <Trans i18nKey="steps:personalInformation.birthdayLabel"></Trans>
                  }
                  helperText={
                    showErrors.birthDate
                      ? t("common:errors.incorrect_entry")
                      : ""
                  }
                  value={personalInfo.birthDate}
                  onChange={(date) =>
                    setPersonalInfo({ ...personalInfo, birthDate: date })
                  }
                  onBlur={() => {
                    saveToCookie(personalInfo, stepState.step);
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                data-cy="birth_place"
                className={classes.input}
                id="outlined-required"
                value={personalInfo.birthPlace}
                onChange={(event) =>
                  setPersonalInfo({
                    ...personalInfo,
                    birthPlace: event.target.value,
                  })
                }
                onBlur={() => {
                  saveToCookie(personalInfo, stepState.step);
                }}
                label={
                  <Trans i18nKey="steps:personalInformation.birthcityLabel"></Trans>
                }
                placeholder={t(
                  "steps:personalInformation.birthCityPlaceholder"
                )}
                variant="outlined"
              />
            </div>

            <div>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.input}
                id="outlined-required"
                value={personalInfo.job}
                onChange={(event) =>
                  setPersonalInfo({ ...personalInfo, job: event.target.value })
                }
                onBlur={() => {
                  saveToCookie(personalInfo, stepState.step);
                }}
                label={
                  <Trans i18nKey="steps:personalInformation.workLabel"></Trans>
                }
                placeholder={t("steps:personalInformation.workPlaceholder")}
                variant="outlined"
              />
            </div>
          </Grid>

          {/* KONTACT INFORMATIONEN */}
          <Grid item xs={12}>
            <h2 className="blue-heading mt-8">
              <Trans i18nKey="steps:personalInformation.contactinformation"></Trans>
            </h2>
          </Grid>
          <Grid item sm={4} xs={12}>
            <p className="font-medium text-md mb-4 text-gray-600">
              <Trans i18nKey="steps:personalInformation.contactIntrotext"></Trans>
            </p>
          </Grid>
          <Grid item sm={8} xs={12}>
            <PlacesAutocomplete
              setAddressInfo={setAddressInfo}
              addressInfo={addressInfo}
              countriesList={countriesList}
              setCountryLocale={setCountryLocale}
              stepName={stepState.step}
            />
            <Grid item spacing={3}>
              <div>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  data-cy="street_address"
                  error={showErrors.addressStreet ? true : false}
                  helperText={
                    showErrors.addressStreet
                      ? t("common:errors.incorrect_entry")
                      : ""
                  }
                  className={classes.input}
                  id="outlined-required"
                  value={addressInfo.addressStreet}
                  onChange={(event) =>
                    setAddressInfo({
                      ...addressInfo,
                      addressStreet: event.target.value,
                    })
                  }
                  onBlur={() => {
                    saveToCookie(addressInfo, stepState.step);
                  }}
                  label={
                    <Trans i18nKey="steps:personalInformation.streetLabel"></Trans>
                  }
                  placeholder={t("steps:personalInformation.streetPlaceholder")}
                  variant="outlined"
                />
              </div>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} md={3}>
                <div>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    data-cy="address_plz"
                    error={showErrors.addressPLZ ? true : false}
                    helperText={
                      showErrors.addressPLZ
                        ? t("common:errors.incorrect_entry")
                        : ""
                    }
                    className={classes.input}
                    id="outlined-required"
                    value={addressInfo.addressPLZ}
                    onChange={(event) =>
                      setAddressInfo({
                        ...addressInfo,
                        addressPLZ: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(addressInfo, stepState.step);
                    }}
                    label={
                      <Trans i18nKey="steps:personalInformation.postLabel"></Trans>
                    }
                    placeholder={t("steps:personalInformation.postPlaceholder")}
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                <div>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    data-cy="address_residency"
                    error={showErrors.addressResidency ? true : false}
                    helperText={
                      showErrors.addressResidency
                        ? t("common:errors.incorrect_entry")
                        : ""
                    }
                    className={classes.input}
                    id="outlined-required"
                    value={addressInfo.addressResidency}
                    onChange={(event) =>
                      setAddressInfo({
                        ...addressInfo,
                        addressResidency: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(addressInfo, stepState.step);
                    }}
                    label={
                      <Trans i18nKey="steps:personalInformation.citylivingLabel"></Trans>
                    }
                    placeholder={t(
                      "steps:personalInformation.citylivingPlaceholder"
                    )}
                    variant="outlined"
                  />
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                id="outlined-required"
                options={createCountriesList(countriesList)}
                autoHighlight
                onChange={(event, newValue) => {
                  setCountry(newValue);
                  setAddressInfo({
                    ...addressInfo,
                    addressCountry: newValue,
                  });
                }}
                getOptionSelected={(option, value) => {
                  return option === value || {};
                }}
                onSelect={() => {
                  saveToCookie(addressInfo, stepState.step);
                }}
                value={addressInfo.addressCountry}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...params}
                    data-cy="address_country"
                    className={classes.select}
                    label={
                      <Trans i18nKey="steps:personalInformation.countryLabel"></Trans>
                    }
                    placeholder={t(
                      "steps:personalInformation.countryPlaceholder"
                    )}
                    variant="outlined"
                    error={showErrors.addressCountry ? true : false}
                    helperText={
                      showErrors.addressCountry
                        ? t("common:errors.incorrect_entry")
                        : ""
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                id="outlined-required"
                options={nationalitiesList}
                autoHighlight
                onChange={(event, newValue) => {
                  setChosenNationality(newValue);
                  setAddressInfo({
                    ...addressInfo,
                    nationality: newValue?.nationality?.translation,
                  });
                }}
                value={getNationalityValue(nationalitiesList)}
                onBlur={() => {
                  saveToCookie(addressInfo, stepState.step);
                }}
                getOptionLabel={(option) => option?.nationality?.translation}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...params}
                    data-cy="address_nationality"
                    className={classes.select}
                    label={
                      <Trans i18nKey="steps:personalInformation.nationalityLabel"></Trans>
                    }
                    placeholder={t(
                      "steps:personalInformation.nationalityPlaceholder"
                    )}
                    variant="outlined"
                    error={showErrors.nationality ? true : false}
                    helperText={
                      showErrors.nationality
                        ? t("common:errors.incorrect_entry")
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            <div>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                data-cy="email"
                error={showErrors.email ? true : false}
                helperText={
                  showErrors.email ? t("common:errors.incorrect_entry") : ""
                }
                className={classes.input}
                id="outlined-required"
                value={personalInfo.email}
                onChange={(event) =>
                  setPersonalInfo({
                    ...personalInfo,
                    email: event.target.value,
                  })
                }
                onBlur={() => {
                  saveToCookie(personalInfo, stepState.step);
                }}
                label={
                  <Trans i18nKey="steps:personalInformation.emailLabel"></Trans>
                }
                placeholder={t("steps:personalInformation.emailPlaceholder")}
                variant="outlined"
                title="Please provide only a Best Startup Ever corporate e-mail address"
                type="email"
              />
            </div>

            <div>
              <MuiPhoneNumber
                defaultCountry="es"
                InputLabelProps={{
                  shrink: true,
                }}
                data-cy="contact_phone"
                error={showErrors.contactPhone ? true : false}
                helperText={
                  showErrors.contactPhone
                    ? t("common:errors.incorrect_entry")
                    : ""
                }
                className={classes.input}
                id="outlined-required"
                value={addressInfo.contactPhone}
                onChange={(phone) =>
                  setAddressInfo({
                    ...addressInfo,
                    contactPhone: phone,
                  })
                }
                onBlur={() => {
                  saveToCookie(addressInfo, stepState.step);
                }}
                label={
                  <Trans i18nKey="steps:personalInformation.TelefonnummerLabel"></Trans>
                }
                placeholder={t(
                  "steps:personalInformation.TelefonnummerPlaceholder"
                )}
                variant="outlined"
              />
            </div>

            <div>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.input}
                id="outlined-required"
                value={addressInfo.contactFax}
                onChange={(event) =>
                  setAddressInfo({
                    ...addressInfo,
                    contactFax: event.target.value,
                  })
                }
                onBlur={() => {
                  saveToCookie(addressInfo, stepState.step);
                }}
                onWheel={() => document.activeElement.blur()}
                label={
                  <Trans i18nKey="steps:personalInformation.faxLabel"></Trans>
                }
                placeholder={t("steps:personalInformation.faxPlaceholder")}
                variant="outlined"
                type="number"
              />
            </div>

            <div>
              <MuiPhoneNumber
                defaultCountry="es"
                InputLabelProps={{
                  shrink: true,
                }}
                data-cy="contact_other_phone_number"
                className={classes.input}
                id="outlined-required"
                value={addressInfo.contactOtherPhoneNumber}
                onChange={(otherPhoneNum) =>
                  setAddressInfo({
                    ...addressInfo,
                    contactOtherPhoneNumber: otherPhoneNum,
                  })
                }
                onBlur={() => {
                  saveToCookie(addressInfo, stepState.step);
                }}
                label={
                  <Trans i18nKey="steps:personalInformation.weitereTelefonnummerLabel"></Trans>
                }
                placeholder={t(
                  "steps:personalInformation.weitereTelefonnummerPlaceholder"
                )}
                variant="outlined"
              />
            </div>
          </Grid>

          {/* EMERGENCY CONTACT INFORMATIONEN */}
          <Grid item xs={12}>
            <h2 className="blue-heading mt-8">
              <Trans i18nKey="steps:personalInformation.heading_emergencyContact"></Trans>
            </h2>
          </Grid>
          <Grid item sm={4} xs={12}>
            <p className={`font-medium text-md mb-4 text-gray-600`}>
              <Trans i18nKey="steps:personalInformation.text_emergencyContact"></Trans>
            </p>
          </Grid>
          <Grid item sm={8} xs={12}>
            <div>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                data-cy="emergency_contact_name"
                className={classes.input}
                id="outlined-required"
                value={addressInfo.emergencyContactName}
                onChange={(event) =>
                  setAddressInfo({
                    ...addressInfo,
                    emergencyContactName: event.target.value,
                  })
                }
                onBlur={() => {
                  saveToCookie(addressInfo, stepState.step);
                }}
                label={
                  <Trans i18nKey="steps:personalInformation.name_emergencyContact"></Trans>
                }
                placeholder={t(
                  "steps:personalInformation.name_emergencyContact"
                )}
                variant="outlined"
              />
            </div>
            <div>
              <MuiPhoneNumber
                defaultCountry="es"
                InputLabelProps={{
                  shrink: true,
                }}
                data-cy="emergency_contact_mobile"
                className={classes.input}
                id="outlined-required"
                value={addressInfo.emergencyContactPhone}
                onChange={(emergencyNum) => {
                  setAddressInfo({
                    ...addressInfo,
                    emergencyContactPhone: emergencyNum,
                  });
                }}
                onBlur={() => {
                  saveToCookie(addressInfo, stepState.step);
                }}
                label={
                  <Trans i18nKey="steps:personalInformation.phone_emergencyContact"></Trans>
                }
                placeholder={t(
                  "steps:personalInformation.phone_emergencyContact"
                )}
                variant="outlined"
              />
            </div>
          </Grid>

          {/* LANGUAGE INFORMATIONEN */}
          <Grid item xs={12}>
            <h2 className="blue-heading mt-8">
              <Trans i18nKey="steps:personalInformation.languageInformation"></Trans>
            </h2>
          </Grid>
          <Grid item sm={4} xs={12} className="mb-12">
            <p
              className={`${
                showErrors.language && `text-red-500`
              } font-medium text-md mb-4 text-gray-600`}
            >
              <Trans i18nKey="steps:personalInformation.languageIntrotext"></Trans>
            </p>
          </Grid>
          <Grid item sm={8} xs={12} className="mb-18 language-checkboxes">
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy="language_german"
                      checked={languageInfo.languageGerman}
                      onChange={(event) =>
                        setLanguageInfo({
                          ...languageInfo,
                          languageGerman: !languageInfo.languageGerman,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <Trans i18nKey="steps:personalInformation.german"></Trans>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy="language_english"
                      checked={languageInfo.languageEnglish}
                      onChange={(event) =>
                        setLanguageInfo({
                          ...languageInfo,
                          languageEnglish: !languageInfo.languageEnglish,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <Trans i18nKey="steps:personalInformation.english"></Trans>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={languageInfo.languageFrench}
                      onChange={(event) =>
                        setLanguageInfo({
                          ...languageInfo,
                          languageFrench: !languageInfo.languageFrench,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <Trans i18nKey="steps:personalInformation.french"></Trans>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={languageInfo.languageSpanish}
                      onChange={(event) =>
                        setLanguageInfo({
                          ...languageInfo,
                          languageSpanish: !languageInfo.languageSpanish,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <Trans i18nKey="steps:personalInformation.spanish"></Trans>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={languageInfo.languageIsNotFluent}
                      onChange={(event) =>
                        setLanguageInfo({
                          ...languageInfo,
                          languageIsNotFluent:
                            !languageInfo.languageIsNotFluent,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <Trans i18nKey="steps:personalInformation.less_proficiency"></Trans>
                  }
                />
              </Grid>
              <Grid item xs={12} className="pt-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={languageInfo.languageOther}
                      onChange={(event) =>
                        setLanguageInfo({
                          ...languageInfo,
                          languageOther: !languageInfo.languageOther,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="other__input"
                      id="outlined-required"
                      value={languageInfo.languageOtherDetail}
                      onChange={(event) =>
                        setLanguageInfo({
                          ...languageInfo,
                          languageOtherDetail: event.target.value,
                        })
                      }
                      disabled={languageInfo.languageOther ? false : true}
                      placeholder={t("steps:personalInformation.other_label")}
                      variant="outlined"
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="buttons_bar">
            {toggleError && (
              <Grid item xs={12}>
                <p className="font-medium text-md mb-4 text-gray-600 flex justify-end">
                  <Trans i18nKey="steps:personalInformation.validation_error_message"></Trans>
                </p>
              </Grid>
            )}
            <Grid item xs={12}>
              <p className="mt-2">
                <Trans i18nKey="steps:general.mandatory"></Trans>
              </p>
            </Grid>
            <Grid item xs={6} className="mt-6">
              <Button
                data-cy="gostepback"
                variant="contained"
                size="large"
                color="default"
                onClick={stepBackHandler}
              >
                <Trans i18nKey="steps:personalInformation.previous"></Trans>
              </Button>
            </Grid>
            <Grid xs={6} container justify="flex-end" className="mt-6">
              <Button
                data-cy="submit"
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                onClick={() => scrollToTopHandler()}
              >
                <Trans i18nKey="steps:personalInformation.next"></Trans>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default PersonalInformationStep;

import { createMuiTheme } from '@material-ui/core/styles';

export const themeColors = {
    gray: {
        dark1: '#535250',
        fade1: '#D9D8D3',
        fade2: '#E9E8E4',
        fade3: '#BDBCB7'
    },
    blue: {
        dark1: '#5BB6D6',
        fade1: '#fff',
    }
}

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#5BB6D6',
            light: '#5BB6D6',
            contrastText: '#F8F8F6'
        },
        secondary: {
            main: '#767673',
            light: '#E5E5E5',
            contrastText: '',

        },
    },
});
import React from 'react'
import Radio from "@material-ui/core/Radio";
import { Link } from '@material-ui/core';

export const RadioWithImage = ({imageUrl, label, ampliusFastLogo, linkText, href, alt, ...rest}) => {

    return (
    <div style={{display: "flex"}} className="w-full">
        <img
            className="w-1/2 h-44 object-cover rounded-md"
            alt=""
            src={imageUrl}
        />

        <div className="flex flex-col items-start w-1/2">
            <div className="ml-4 flex">
                <Radio className="-ml-2" {...rest} />
                <div className="pt-2">
                    <span className="flex align-baseline text-gray-600">{label}</span>
                </div>
            </div>
            <img src={ampliusFastLogo} alt="Amplius Med Logo" className="h-7 ml-12 mb-2 hidden md:block"/>
            <Link
            className="ml-12"
            target="_blank"
            href={href}
            alt={alt}
            >
            {linkText}
            </Link>
        </div>
    </div>
    );
    };

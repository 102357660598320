import React, { useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import { useCookies } from "react-cookie";

import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import sendPostRequest from "../service";
import { LoadingSpinner } from "../../components/LoadingSpinner";

import { initalState } from "./initialState";
import {
  getActiveOrPersistedStepState,
  getActiveOrPersistedDoneState,
  getActiveOrPersistedStepIndex,
} from "../util/stepPersistence";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",
  },
}));

const stepConnector = <ChevronRightIcon className="blue-text" />;

function StepCustomLabel({
  label,
  i18nLabel,
  stepIndex,
  active,
  isDone,
  onClick,
}) {
  const { t } = useTranslation(["common", "steps"]);

  const onButtonClick = () => {
    onClick(stepIndex);
  };

  const className = `font-bold bg-white h-8 px-2 rounded-md blue-text flex items-center justify-center ${
    active ? "blue-border" : "border-0"
  } ${isDone || active ? "blue-text" : "blue-text-light"}`;

  return (
    <button className={className} onClick={onButtonClick}>
      {isDone && <Check className="mr-1 blue-text" />}
      {t(i18nLabel)}
    </button>
  );
}

export default function StepContainer({ steps }) {
  const [cookies, setCookie] = useCookies("buchinger");

  const [stepsState, setStepsState] = useState(
    getActiveOrPersistedStepState(initalState, cookies)
  );
  const [activeStep, setActiveStep] = useState(
    getActiveOrPersistedStepIndex(0)
  );
  const [lastStep, setLastStep] = useState(0);
  const [doneState, setDoneState] = useState(
    getActiveOrPersistedDoneState(steps.map((step) => false))
  );
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const gotToStep = (stepIndex) => {
    setActiveStep(stepIndex);
    //scrolltop especially on mobile after screen change
    const stepperTop = document.getElementById("stepper-top");
    stepperTop.scrollIntoView();
  };

  const handleBack = (stepValue) => {
    const stepIndex = stepsState.findIndex(
      (item) => item.step === stepValue.step
    );
    const updatedStepsState = [...stepsState];
    updatedStepsState[stepIndex] = stepValue;
    setStepsState(() => updatedStepsState);

    gotToStep(stepIndex - 1);
    const stepperTop = document.getElementById("stepper-top");
    stepperTop.scrollIntoView();
  };

  const handleStepSubmit = async (stepValue) => {
    const stepIndex = stepsState.findIndex(
      (item) => item.step === stepValue.step
    );
    const updatedStepsState = [...stepsState];
    updatedStepsState[stepIndex] = stepValue;
    setStepsState(() => updatedStepsState);
    const updatedArray = [...doneState];
    updatedArray[stepIndex] = true;
    setDoneState(() => updatedArray);
    // final step
    const finalStep = "booking-step-privacy";
    if (stepValue.step === finalStep) {
      setLastStep(stepIndex);
    } else {
      gotToStep(stepIndex + 1);
    }
  };

  React.useEffect(() => {
    async function postData() {
      if (lastStep === 5) {
        const postBody = new Array(6)
          .fill(undefined)
          .reduce((acc, cur, index) => ({ ...acc, ...stepsState[index] }), {});
        setLoadingSpinner(true);
        await sendPostRequest(postBody);
        gotToStep(lastStep + 1);
        setLoadingSpinner(false);
      }
    }
    postData();
  }, [lastStep, stepsState]);

  const StepRendererComponent = steps[activeStep].comp;
  const stepProps = {
    stepState: stepsState[activeStep],
    postState: stepsState,
    onSubmit: handleStepSubmit,
    onGoBack: handleBack,
  };
  const classes = useStyles();

  return (
    <div id="stepper-top">
      <div className="w-full overflow-auto">
        <Stepper
          classes={classes}
          className="hide-mobile w-max"
          activeStep={activeStep}
          connector={stepConnector}
        >
          {steps.map(({ label, i18nLabel, comp }, index) => {
            return (
              <Step className="w-fit" key={label}>
                <StepLabel
                  StepIconProps={{
                    label,
                    i18nLabel,
                    stepIndex: index,
                    isDone: doneState[index],
                    onClick: gotToStep,
                  }}
                  StepIconComponent={StepCustomLabel}
                />
              </Step>
            );
          })}
        </Stepper>
      </div>
      {loadingSpinner ? (
        <LoadingSpinner />
      ) : (
        <div className="md:w-full md:p-8 md:white-container">
          <div className="bg-white md:border md:border-solid border-gray-300 rounded-md step-container">
            <StepRendererComponent {...stepProps} />
          </div>
        </div>
      )}
    </div>
  );
}

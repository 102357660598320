export const initalState = [
  {
    step: "booking-step-welcome",
    firstTime: null,
  },
  {
    step: "booking-step-personal-info",
    gender: "",
    title: "",
    lastName: "",
    firstName: "",
    addressPLZ: "",
    addressResidency: "",
    addressStreet: "",
    contactPerson: "",
    contactPhone: "",
    contactOtherPhoneNumber: "",
    contactFax: "",
    job: "",
    emergencyContactName: "",
    emergencyContactPhone: "",
    languageGerman: false,
    languageEnglish: false,
    languageFrench: false,
    languageSpanish: false,
    languageIsNotFluent: false,
    languageOther: false,
    birthDate: null,
    birthPlace: "",
    nationality: "",
    email: "",
    addressCountry: "",
  },
  {
    step: "booking-step-visit",
    mediaTV: false,
    mediaWLAN: false,
    therapyType: "fasting",
    talk: "no",
    talkDetail: "",
    arrivalDate: null,
    departureDate: null,
    expectedTimeOfArrival: "",
    travellingByCar: false,
    shuttleRequested: false,
  },
  {
    step: "booking-step-health",
    isCovidPositive: "no",
    hasCovidSymptoms: "no_specification",
    reasonPrevention: false,
    reasonRegeneration: false,
    reasonWeight: false,
    reasonSmoke: false,
    reasonWeightAndLifestyle: false,
    reasonAcute: false,
    reasonAcuteDetail: "",
    reasonChronic: false,
    reasonChronicDetail: "",
    reasonLocomotive: false,
    reasonLocomotiveDetail: "",
    reasonDigestive: false,
    reasonOther: "",
    reasonNoSpecification: false,
    reasonDigestiveDetail: "",
    exclusionDiabetes: false,
    exclusionDiabetesType1: false,
    exclusionDiabetesType2: false,
    exclusionCancer: false,
    exclusionDrugs: false,
    exclusionEatingDis: false,
    exclusionSevereLiverAndKidneyDisfunction: false,
    noExclusion: false,
    medicationGeneral: false,
    medication: [],
    other_medication: "",
    disabilityFood: false,
    disabilityFoodDetail: "",
    disabilityMedAllergy: false,
    disabilityMedAllergyDetail: "",
    disabilityLocomotion: false,
    disabilityLocomotionDetail: "",
    disabilityHelp: false,
    disabilityHelpDetail: "",
    weight: "",
    height: "",
    miscellaneous: "",
  },
  {
    step: "booking-step-closing",
    referrer: "",
    referrerDetail: "",
    legalAGB: false,
    legalPrivacy: false,
    chosenLanguage: "",
  },
  {
    step: "booking-step-privacy",
    legalDevelopAndUse: false,
    legalImprovement: false,
    legalScientific: false,
    legalContinuity: false,
    legalNewsletter: false,
    legalConfidentiality: false,
    chosenLanguage: "",
    omi_id: "",
  },
];

import React from 'react'
import Loader from "react-loader-spinner";

export const LoadingSpinner = () => {
  return (
    <div className="overlay">
      <Loader
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
      />
    </div>
  )
}

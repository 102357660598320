import React, {createContext} from 'react'
import { useState } from 'react'

export const LanguageContext = createContext({
    lang: '',
})

export const LanguageProvider = ({children}) => {
    const [language, setLanguage] = useState('es')

    return (
        <LanguageContext.Provider value={{language, setLanguage}}>
            {children}
        </LanguageContext.Provider>
    )
}
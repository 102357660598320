import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import React from 'react'

export default function RadioWithText({
  checkedInfo,
  setCheckedInfo,
  ariaLabel = "some-aria-label",
  name="name",
  headline,
  text,
  labelOption1,
  labelOption2,
  showErrors,
  cypressClass
}) {
  return (
        <div className='flex flex-col md:flex-row'>
          {headline &&
            <Grid xs={12} md={8} className={`mt-2.5 ${showErrors && `text-red-500`}`}>
              {headline}
            </Grid>
          }
          {text &&
            <Grid xs={12} md={8} className='mt-2.5'>
              {text}
            </Grid>
          }

          <Grid item xs={12} md={4} spacing={3} className="md:mt-auto">
            <RadioGroup
              className='md:justify-end md:flex-row'
              aria-label={ariaLabel}
              name={name}
              value={checkedInfo}
              defaultChecked={checkedInfo}
              onChange={setCheckedInfo}
            >
              <div>
                <FormControlLabel
                  data-cy={cypressClass}
                  value={true}
                  control={<Radio color="primary" />}
                  label={labelOption1}
                />
              </div>
              <div>
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label={labelOption2}
                />
              </div>
            </RadioGroup>
          </Grid>
        </div>
  )
}
export const getActiveOrPersistedStepState = (initState, cookies) => {
  if (cookies?.buchinger) {
    cookies.buchinger.forEach((stepData) => {
      let stepName = stepData.step;
      let activeStepper = initState.find((item) => {
        return item?.step === stepName;
      });

      Object.entries(stepData).forEach(([key, value]) => {
        if(!activeStepper) return;
         activeStepper[key] = value;
      });
    });
  }  return initState;
  // const persistedState = JSON.parse(localStorage.getItem("diev_ls_willhami_curr_form_state"));
  // return persistedState || intialState;
};

export const getActiveOrPersistedDoneState = (initState) => {
  return initState;
  // const persistedState = JSON.parse(localStorage.getItem("diev_ls_willhami_curr_form_get_l_d_state"));
  // return persistedState || intialState;
};

export const getActiveOrPersistedStepIndex = (initState) => {
  return initState;
  // const persistedState = JSON.parse(localStorage.getItem("diev_ls_willhami_curr_form_get_a_s_state"));
  // return persistedState || intialState;
};

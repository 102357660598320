import React from 'react';

const publicUrl = process.env.PUBLIC_URL;

const config = {
    publicUrl,
    imagesUrl: `${publicUrl}/assets/images/wizard/`,
    apiUrl: "https://4chxx6mj2k.execute-api.eu-central-1.amazonaws.com/"
}

const ConfigContext = React.createContext(config);

export default ConfigContext;
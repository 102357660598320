import WizardTitleHeader from './WizardTitleHeader';
import StepContainer from './steps/StepContainer';
// import useConfig from '../hooks';
import steps from './stepsMeta';


function WizardContainer() {
    return (
        <div className="w-screen flex flex-col app-container">
            <WizardTitleHeader />
            <StepContainer steps={steps} />
        </div>
    );
}

export default WizardContainer;

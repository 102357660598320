import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { FeedbackMessage } from "../../components/FeedbackMessage";
import { scrollToTopHandler } from "../util/scrollToTopHandler";
import { saveToCookie } from "../util/cookiesHelper";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  select: {
    width: "100%",
  },
  input: {
    margin: theme.spacing(1),
    width: "100%",
    "& .MuiFormLabel-root": {
      fontStyle: "italic",
      fontWeight: "bold",
    },
  },
}));

const extractReasons = (stepState) => {
  return {
    step: stepState.step,
    reasonPrevention: stepState.reasonPrevention,
    reasonRegeneration: stepState.reasonRegeneration,
    reasonWeight: stepState.reasonWeight,
    reasonSmoke: stepState.reasonSmoke,
    reasonWeightAndLifestyle: stepState.reasonWeightAndLifestyle,
    reasonAcute: stepState.reasonAcute,
    reasonAcuteDetail: stepState.reasonAcuteDetail,
    reasonChronic: stepState.reasonChronic,
    reasonChronicDetail: stepState.reasonChronicDetail,
    reasonLocomotive: stepState.reasonLocomotive,
    reasonLocomotiveDetail: stepState.reasonLocomotiveDetail,
    reasonDigestive: stepState.reasonDigestive,
    reasonDigestiveDetail: stepState.reasonDigestiveDetail,
    reasonOther: stepState.reasonOther,
  };
};

const extractExclusion = (stepState) => {
  return {
    exclusionDiabetesType1: stepState.exclusionDiabetesType1,
    exclusionDiabetesType2: stepState.exclusionDiabetesType2,
    exclusionCancer: stepState.exclusionCancer,
    exclusionDrugs: stepState.exclusionDrugs,
    exclusionEatingDis: stepState.exclusionEatingDis,
    exclusionSevereLiverAndKidneyDisfunction:
      stepState.exclusionSevereLiverAndKidneyDisfunction,
    noExclusion: stepState.noExclusion,
  };
};

const extractDisabilities = (stepState) => {
  return {
    disabilityFood: stepState.disabilityFood,
    disabilityFoodDetail: stepState.disabilityFoodDetail,
    disabilityMedAllergy: stepState.disabilityMedAllergy,
    disabilityMedAllergyDetail: stepState.disabilityMedAllergyDetail,
    disabilityLocomotion: stepState.disabilityLocomotion,
    disabilityLocomotionDetail: stepState.disabilityLocomotionDetail,
    disabilityHelp: stepState.disabilityHelp,
    disabilityHelpDetail: stepState.disabilityHelpDetail,
  };
};

const extractGeneralInfo = (stepState) => {
  return {
    weight: stepState.weight,
    height: stepState.height,
    medicationGeneral: stepState.medicationGeneral,
    medication: stepState.medication,
    otherMedication: stepState.otherMedication,
    miscellaneous: stepState.miscellaneous,
    isCovidPositive: stepState.isCovidPositive,
    hasCovidSymptoms: stepState.hasCovidSymptoms,
  };
};

function HealthStep({ stepState, onSubmit, onGoBack }) {
  const classes = useStyles();
  const { t } = useTranslation(["translation", "common"]);
  const medications = [
    {
      value: "medicationBloodThinning",
      label: t("steps:healthStep.forminpu2Label2"),
    },
    {
      value: "medicationDiabetes",
      label: t("steps:healthStep.forminpu2Label3"),
    },
    {
      value: "medicationImmuneSuppressive",
      label: t("steps:healthStep.forminpu2Label4"),
    },
    { value: "medicationOther", label: t("steps:healthStep.forminpu2Label5") },
  ];

  const [generalInfo, setGeneralInfo] = useState(extractGeneralInfo(stepState));
  const [reasons, setReasons] = useState(extractReasons(stepState));
  const [exclusions, setExclusions] = useState(extractExclusion(stepState));
  const [disabilities, setDisabilities] = useState(
    extractDisabilities(stepState)
  );
  const [toggleError, setToggleError] = useState(false);
  const [showErrors, setShowErrors] = useState({
    validationPassed: false,
    reasonAcuteDetail: false,
    reasonChronicDetail: false,
    reasonLocomotiveDetail: false,
    reasonDigestiveDetail: false,
    disabilityFoodDetail: false,
    disabilityMedAllergyDetail: false,
    disabilityLocomotionDetail: false,
    disabilityHelpDetail: false,
    exclusions: false,
    reasons: false,
    height: false,
    weight: false,
    drugName: false,
  });
  useEffect(() => {
    saveToCookie(exclusions, stepState.step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exclusions]);

  useEffect(() => {
    saveToCookie(reasons, stepState.step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasons]);

  const addMedication = () => {
    const newElement = {
      index: Math.random(),
      agent: "",
      name: "",
      dosage_morning: "",
      dosage_noon: "",
      dosage_evening: "",
      dosage_night: "",
    };
    setGeneralInfo((prevState) => ({
      ...prevState,
      medication: [...prevState.medication, newElement],
    }));
  };

  const removeMedication = (medicationObj) => {
    setGeneralInfo((prevState) => ({
      ...prevState,
      medication: prevState.medication.filter(
        (item) => item.index !== medicationObj.index
      ),
    }));
  };

  const handleMedicationChange = (event, medicationObj, key) => {
    const indexOfObj = generalInfo.medication.findIndex((item) => {
      return item.index === medicationObj.index;
    });

    setGeneralInfo((prevState) => {
      const updateMedication = [...prevState.medication];
      updateMedication[indexOfObj][key] = event.target.value;
      return { ...prevState, medication: updateMedication };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formState = {
      ...disabilities,
      ...reasons,
      ...exclusions,
      ...generalInfo,
    };
    if (!validateFormHandler(formState)) {
      return;
    }

    onSubmit(formState);
  };

  const validateFormHandler = (formState) => {
    let error = { ...showErrors };
    let validationPassed = true;

    const validateField = (key) => {
      let detailKey = `${key}Detail`;
      if (
        formState[key] &&
        !formState[detailKey] &&
        key !== "disease" &&
        key !== "weight" &&
        key !== "height" &&
        key !== "drugName" &&
        !key.includes("reason")
      ) {
        error[detailKey] = true;
        validationPassed = false;
      } else {
        error[detailKey] = false;
      }

      if (key === "height" || key === "weight") {
        if (formState[key] === "") {
          error[key] = true;
          validationPassed = false;
          return error;
        } else {
          error[key] = false;
          return error;
        }
      }

      if (
        key === "drugName" &&
        generalInfo.medicationGeneral &&
        !formState.medication[0].name
      ) {
        error[key] = true;
        validationPassed = false;
      } else {
        error[key] = false;
      }

      if (key === "exclusions") {
        // for exclusion validation
        if (
          exclusions.exclusionDiabetesType1 ||
          exclusions.exclusionDiabetesType2 ||
          exclusions.exclusionCancer ||
          exclusions.exclusionDrugs ||
          exclusions.exclusionEatingDis ||
          exclusions.exclusionSevereLiverAndKidneyDisfunction ||
          exclusions.noExclusion
        ) {
          // hide error if checkbox is checked
          error[key] = false;
        } else {
          // show error
          error[key] = true;
          validationPassed = false;
        }
      }

      if (key === "reasons") {
        error[key] = true;

        if (
          reasons.reasonPrevention ||
          reasons.reasonRegeneration ||
          reasons.reasonWeight ||
          reasons.reasonSmoke ||
          reasons.reasonWeightAndLifestyle ||
          reasons.reasonAcute ||
          reasons.reasonChronic ||
          reasons.reasonLocomotive ||
          reasons.reasonDigestive
        ) {
          // hide error if checkbox is checked
          error[key] = false; // hide error
        }

        if (reasons.reasonAcute && !reasons.reasonAcuteDetail) {
          //checked and input is empty
          error["reasonAcuteDetail"] = true;
        } else {
          error["reasonAcuteDetail"] = false;
        }

        if (reasons.reasonChronic && !reasons.reasonChronicDetail) {
          //checked and input is empty
          error["reasonChronicDetail"] = true;
        } else {
          error["reasonChronicDetail"] = false;
        }

        if (reasons.reasonLocomotive && !reasons.reasonLocomotiveDetail) {
          //checked and input is empty
          error["reasonLocomotiveDetail"] = true;
        } else {
          error["reasonLocomotiveDetail"] = false;
        }

        if (reasons.reasonDigestive && !reasons.reasonDigestiveDetail) {
          //checked and input is empty
          error["reasonDigestiveDetail"] = true;
        } else {
          error["reasonDigestiveDetail"] = false;
        }

        if (
          error[key] ||
          (reasons.reasonAcute && error["reasonAcuteDetail"]) ||
          (reasons.reasonChronic && error["reasonChronicDetail"]) ||
          (reasons.reasonLocomotive && error["reasonLocomotiveDetail"]) ||
          (reasons.reasonDigestive && error["reasonDigestiveDetail"])
        ) {
          validationPassed = false;
        } else {
          validationPassed = true;
        }
      }
    };

    [
      "reasons",
      "disabilityFood",
      "disabilityMedAllergy",
      "disabilityLocomotion",
      "disabilityHelp",
      "exclusions",
      "height",
      "weight",
      "drugName",
    ].map((key) => validateField(key));
    setShowErrors((state) => ({
      ...state,
      ...error,
    }));
    !validationPassed ? setToggleError(true) : setShowErrors(false);
    return validationPassed;
  };

  const stepBackHandler = (event) => {
    event.preventDefault();

    const formState = {
      ...disabilities,
      ...reasons,
      ...exclusions,
      ...generalInfo,
    };
    onGoBack(formState);
  };

  const exclusionsHandler = (element, value) => {
    setExclusions((state) => ({
      ...state,
      [element]: !value,
      noExclusion: false,
    }));
  };

  const noExclusionsHandler = (event) => {
    setExclusions((state) => ({
      ...state,
      exclusionDiabetesType1: false,
      exclusionDiabetesType2: false,
      exclusionCancer: false,
      exclusionDrugs: false,
      exclusionEatingDis: false,
      exclusionSevereLiverAndKidneyDisfunction: false,
      noExclusion: true,
    }));
  };

  return (
    <div className={classes.root}>
      <div id="feedback-message"></div>
      <div className="md:hidden mb-12 flex flex-col items-center">
        <button className="rounded-md px-2 border-solid border-blue-500 justify-center border text-blue-500">
          <Trans i18nKey="common:headings.health_mobile">
            ( 4 / 5 ) Health
          </Trans>
        </button>
      </div>
      {toggleError && (
        <FeedbackMessage
          error
          heading={
            <Trans i18nKey="common:errors.error_message_heading"></Trans>
          }
          description={
            <Trans i18nKey="common:errors.error_message_description"></Trans>
          }
        />
      )}
      <form action="#" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item className={`${classes.paper} health_header`}>
            <p className="grey-heading mb-4">
              <Trans i18nKey="steps:healthStep.stepTopic"></Trans>
            </p>
            <h2 className="xs:break-all font-medium text-4xl text-gray-700 mb-4">
              <Trans i18nKey="steps:healthStep.stepGreeting"></Trans>
            </h2>
          </Grid>

          {/* COVID Questions */}
          <Grid item xs={12}>
            <p className="blue-heading mt-8 mb-2">
              <Trans i18nKey="steps:healthStep.heading_covid"></Trans>
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className="font-medium text-md text-gray-600">
              <Trans i18nKey="steps:healthStep.heading_is_covid_positive"></Trans>
            </p>
          </Grid>

          <Grid item xs={12} spacing={3}>
            <RadioGroup
              aria-label="is-covid-positive"
              name="is-covid-positive"
              value={generalInfo.isCovidPositive}
              defaultChecked={generalInfo.isCovidPositive}
              onChange={
                (event) =>
                  setGeneralInfo({
                    ...generalInfo,
                    isCovidPositive: event.target.value,
                  })
                // console.log(event.target.value)
              }
              onBlur={() => {
                saveToCookie(generalInfo, stepState.step);
              }}
            >
              <div>
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label={<Trans i18nKey="steps:visitStep.label_no"></Trans>}
                />
              </div>
              <div>
                <FormControlLabel
                  data-cy="covid-positive"
                  value="yes"
                  control={<Radio color="primary" />}
                  label={<Trans i18nKey="steps:visitStep.label_yes"></Trans>}
                />
              </div>
              <div>
                <FormControlLabel
                  value="no_specification"
                  control={<Radio color="primary" />}
                  label={
                    <Trans i18nKey="steps:healthStep.label_no_specification" />
                  }
                />
              </div>
            </RadioGroup>
          </Grid>

          {generalInfo.isCovidPositive === "yes" && (
            <>
              <Grid item xs={12}>
                <p className="font-medium text-md my-6 text-gray-600">
                  <Trans i18nKey="steps:healthStep.heading_has_covid_symptoms"></Trans>
                </p>
              </Grid>

              <Grid item xs={12} spacing={3}>
                <RadioGroup
                  data-cy="covid-symptoms-radio-group"
                  aria-label="has-covid-symptoms"
                  name="has-covid-symptoms"
                  value={generalInfo.hasCovidSymptoms}
                  defaultChecked={generalInfo.hasCovidSymptoms}
                  onChange={(event) =>
                    setGeneralInfo({
                      ...generalInfo,
                      hasCovidSymptoms:
                        event.target.value === "yes"
                          ? "yes"
                          : event.target.value === "no"
                          ? "no"
                          : "no_specification",
                    })
                  }
                  onBlur={() => {
                    saveToCookie(generalInfo, stepState.step);
                  }}
                >
                  <div>
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label={<Trans i18nKey="steps:visitStep.label_no"></Trans>}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      data-cy="covid-symptoms"
                      value="yes"
                      control={<Radio color="primary" />}
                      label={
                        <Trans i18nKey="steps:visitStep.label_yes"></Trans>
                      }
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="no_specification"
                      control={<Radio color="primary" />}
                      label={
                        <Trans i18nKey="steps:healthStep.label_no_specification"></Trans>
                      }
                    />
                  </div>
                </RadioGroup>
              </Grid>
            </>
          )}

          {/* Main section */}
          <Grid item xs={12}>
            <p className="blue-heading mt-8 mb-2">
              <Trans i18nKey="steps:healthStep.heading_motivation"></Trans>
            </p>
          </Grid>
          <Grid item sm={8} xs={12}>
            <p
              className={`${
                showErrors.reasons && `text-red-500`
              } font-medium text-md text-gray-600`}
            >
              <Trans i18nKey="steps:healthStep.text_motivation"></Trans>
            </p>
          </Grid>
          <Grid item sm={12} xs={12}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reasons.reasonDigestive}
                    onChange={() =>
                      setReasons({
                        ...reasons,
                        reasonDigestive: !reasons.reasonDigestive,
                      })
                    }
                    onSelect={() => {
                      saveToCookie(reasons, stepState.step);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Trans i18nKey="steps:healthStep.label_digestive_disease"></Trans>
                }
              />
            </div>
            {reasons.reasonDigestive && (
              <div className="dropdown-input">
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={showErrors.reasonDigestiveDetail ? true : false}
                    id="outlined-multiline-static"
                    label={
                      <Trans i18nKey="steps:healthStep.label_diagnosis"></Trans>
                    }
                    placeholder={t(
                      "steps:healthStep.placeholder_how_diagnosis"
                    )}
                    value={reasons.reasonDigestiveDetail}
                    onChange={(event) =>
                      setReasons({
                        ...reasons,
                        reasonDigestiveDetail: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(reasons, stepState.step);
                    }}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              </div>
            )}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reasons.reasonChronic}
                    onChange={() =>
                      setReasons({
                        ...reasons,
                        reasonChronic: !reasons.reasonChronic,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Trans i18nKey="steps:healthStep.heading_chronic_disease"></Trans>
                }
              />
            </div>
            {reasons.reasonChronic && (
              <div className="dropdown-input">
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={showErrors.reasonChronicDetail ? true : false}
                    id="outlined-multiline-static"
                    label={
                      <Trans i18nKey="steps:healthStep.label_diagnosis"></Trans>
                    }
                    placeholder={t(
                      "steps:healthStep.placeholder_how_diagnosis"
                    )}
                    value={reasons.reasonChronicDetail}
                    onChange={(event) =>
                      setReasons({
                        ...reasons,
                        reasonChronicDetail: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(reasons, stepState.step);
                    }}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              </div>
            )}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reasons.reasonLocomotive}
                    onChange={() =>
                      setReasons({
                        ...reasons,
                        reasonLocomotive: !reasons.reasonLocomotive,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Trans i18nKey="steps:healthStep.heading_muscular_disorder"></Trans>
                }
              />
            </div>
            {reasons.reasonLocomotive && (
              <div className="dropdown-input">
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={showErrors.reasonLocomotiveDetail ? true : false}
                    id="outlined-multiline-static"
                    label={
                      <Trans i18nKey="steps:healthStep.label_diagnosis"></Trans>
                    }
                    placeholder={t(
                      "steps:healthStep.placeholder_how_diagnosis"
                    )}
                    value={reasons.reasonLocomotiveDetail}
                    onChange={(event) =>
                      setReasons({
                        ...reasons,
                        reasonLocomotiveDetail: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(reasons, stepState.step);
                    }}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              </div>
            )}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reasons.reasonAcute}
                    onChange={() =>
                      setReasons({
                        ...reasons,
                        reasonAcute: !reasons.reasonAcute,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Trans i18nKey="steps:healthStep.label_acute_illness"></Trans>
                }
              />
            </div>
            {reasons.reasonAcute && (
              <div className="dropdown-input" data-testid="acuteIllness">
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={showErrors.reasonAcuteDetail ? true : false}
                    id="outlined-multiline-static"
                    label={
                      <Trans i18nKey="steps:healthStep.label_diagnosis"></Trans>
                    }
                    placeholder={t(
                      "steps:healthStep.placeholder_how_diagnosis"
                    )}
                    value={reasons.reasonAcuteDetail}
                    onChange={(event) =>
                      setReasons({
                        ...reasons,
                        reasonAcuteDetail: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(reasons, stepState.step);
                    }}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              </div>
            )}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reasons.reasonPrevention}
                    onChange={() =>
                      setReasons({
                        ...reasons,
                        reasonPrevention: !reasons.reasonPrevention,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Trans i18nKey="steps:healthStep.label_prevention"></Trans>
                }
              />
            </div>
            <div>
              <FormControlLabel
                label_regeneration
                control={
                  <Checkbox
                    data-cy="regeneration_checkmark"
                    checked={reasons.reasonRegeneration}
                    onChange={() =>
                      setReasons({
                        ...reasons,
                        reasonRegeneration: !reasons.reasonRegeneration,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Trans i18nKey="steps:healthStep.label_regeneration"></Trans>
                }
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reasons.reasonWeight}
                    onChange={() =>
                      setReasons({
                        ...reasons,
                        reasonWeight: !reasons.reasonWeight,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Trans i18nKey="steps:healthStep.label_weight_reduction"></Trans>
                }
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reasons.reasonSmoke}
                    onChange={() =>
                      setReasons({
                        ...reasons,
                        reasonSmoke: !reasons.reasonSmoke,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Trans i18nKey="steps:healthStep.label_smoking_cesssation"></Trans>
                }
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reasons.reasonWeightAndLifestyle}
                    onChange={() =>
                      setReasons({
                        ...reasons,
                        reasonWeightAndLifestyle:
                          !reasons.reasonWeightAndLifestyle,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Trans i18nKey="steps:healthStep.label_weight_reduction_lifestyle_changes"></Trans>
                }
              />
            </div>

            <Grid item xs={12}>
              <Grid item xs={12}>
                <h2 className="mt-8 mb-6">
                  <Trans i18nKey="steps:healthStep.label_other_reason"></Trans>
                </h2>
              </Grid>
              <Grid container xs={12}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="outlined-multiline-static"
                  value={reasons.reasonOther}
                  onChange={(event) =>
                    setReasons({
                      ...reasons,
                      reasonOther: event.target.value,
                    })
                  }
                  multiline
                  rows={7}
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <h2 className="blue-heading mt-8 mb-6">
                <Trans i18nKey="steps:healthStep.heading_disease"></Trans>
              </h2>
            </Grid>
            <Grid container xs={12}>
              <Grid item sm={12}>
                <p
                  className={`${
                    showErrors.exclusions && `text-red-500`
                  } font-medium text-md mb-4 text-grey-400`}
                >
                  <Trans i18nKey="steps:healthStep.text_select_disease"></Trans>
                </p>
              </Grid>
              <Grid item sm={12}>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-cy="diabetes_checkbox"
                        checked={exclusions.exclusionDiabetesType1}
                        onChange={() =>
                          exclusionsHandler(
                            "exclusionDiabetesType1",
                            exclusions.exclusionDiabetesType1
                          )
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <Trans i18nKey="steps:healthStep.label_diabetes_mellitus_1">
                        {" "}
                        <sup>1</sup>
                      </Trans>
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-cy="diabetes_checkbox2"
                        checked={exclusions.exclusionDiabetesType2}
                        onChange={() =>
                          exclusionsHandler(
                            "exclusionDiabetesType2",
                            exclusions.exclusionDiabetesType2
                          )
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <Trans i18nKey="steps:healthStep.label_diabetes_mellitus_2">
                        {" "}
                        <sup>1</sup>
                      </Trans>
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={exclusions.exclusionCancer}
                        onChange={() =>
                          exclusionsHandler(
                            "exclusionCancer",
                            exclusions.exclusionCancer
                          )
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <Trans i18nKey="steps:healthStep.label_acute_cancer">
                        {" "}
                        <sup>1</sup>
                      </Trans>
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={exclusions.exclusionDrugs}
                        onChange={() =>
                          exclusionsHandler(
                            "exclusionDrugs",
                            exclusions.exclusionDrugs
                          )
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <Trans i18nKey="steps:healthStep.label_severe_substance_dependence">
                        {" "}
                        <sup>1</sup>
                      </Trans>
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={exclusions.exclusionEatingDis}
                        onChange={() =>
                          exclusionsHandler(
                            "exclusionEatingDis",
                            exclusions.exclusionEatingDis
                          )
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <Trans i18nKey="steps:healthStep.label_eating_disorder">
                        {" "}
                        <sup>1</sup>
                      </Trans>
                    }
                  />
                </div>

                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          exclusions.exclusionSevereLiverAndKidneyDisfunction
                        }
                        onChange={() =>
                          exclusionsHandler(
                            "exclusionSevereLiverAndKidneyDisfunction",
                            exclusions.exclusionSevereLiverAndKidneyDisfunction
                          )
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <Trans i18nKey="steps:healthStep.label_severe_liver_kidney_disfunction">
                        {" "}
                        <sup>2</sup>
                      </Trans>
                    }
                  />
                </div>

                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={exclusions.noExclusion}
                        onChange={() => noExclusionsHandler()}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <Trans i18nKey="steps:healthStep.label_none_of_the_above"></Trans>
                    }
                  />
                </div>
                <p
                  className={`${
                    showErrors.exclusions && `text-red-500`
                  } font-medium text-md mt-4 text-grey-300`}
                >
                  <Trans i18nKey="steps:healthStep.heading_fasting">
                    {" "}
                    <sup>1</sup>{" "}
                  </Trans>
                </p>
              </Grid>
            </Grid>
          </Grid>

          {/* second page */}
          <Grid container xs={12} className="mt-6">
            <Grid item xs={12}>
              <h2 className="blue-heading mt-8 mb-6">
                <Trans i18nKey="steps:healthStep.heading_your_body"></Trans>
              </h2>
            </Grid>
            <Grid container sm={12} className="justify-between">
              <Grid item sm={4}>
                <p className="font-medium text-md mb-4 text-grey-400">
                  <Trans i18nKey="steps:healthStep.heading_advance_medical_information"></Trans>
                </p>
              </Grid>
              <Grid container sm={8}>
                <Grid container sm={12} className="mb-6">
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 3);
                    }}
                    min={0}
                    data-cy="height"
                    error={showErrors.height ? true : false}
                    helperText={
                      showErrors.height
                        ? t("common:errors.incorrect_entry")
                        : ""
                    }
                    className="w-full"
                    id="outlined-required"
                    label={
                      <Trans i18nKey="steps:healthStep.label_body_height"></Trans>
                    }
                    placeholder={t("steps:healthStep.placeholder_body_height")}
                    value={generalInfo.height}
                    onChange={(event) =>
                      setGeneralInfo({
                        ...generalInfo,
                        height: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(generalInfo, stepState.step);
                    }}
                    onWheel={() => document.activeElement.blur()}
                    variant="outlined"
                    type="number"
                  />
                </Grid>
                <Grid container sm={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 3);
                    }}
                    data-cy="weight"
                    error={showErrors.weight ? true : false}
                    helperText={
                      showErrors.weight
                        ? t("common:errors.incorrect_entry")
                        : ""
                    }
                    className="w-full"
                    id="outlined-required"
                    label={
                      <Trans i18nKey="steps:healthStep.label_body_weight"></Trans>
                    }
                    placeholder={t("steps:healthStep.placeholder_body_weight")}
                    value={generalInfo.weight}
                    onChange={(event) => {
                      setGeneralInfo({
                        ...generalInfo,
                        weight: event.target.value,
                      });
                    }}
                    onBlur={() => {
                      saveToCookie(generalInfo, stepState.step);
                    }}
                    onWheel={() => document.activeElement.blur()}
                    variant="outlined"
                    type="number"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12} className="mt-6">
            <Grid item xs={12}>
              <h2 className="blue-heading mt-8 mb-6">
                <Trans i18nKey="steps:healthStep.heading_allergy"></Trans>
              </h2>
              <p className="font-medium text-md mb-4 text-grey-400">
                <Trans i18nKey="steps:healthStep.text_food_allergey_suffering"></Trans>
              </p>
            </Grid>
            <Grid container xs={12} className="justify-between">
              <Grid item sm={4} xs={12} spacing={3}>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={disabilities.disabilityFood}
                  onChange={() =>
                    setDisabilities({
                      ...disabilities,
                      disabilityFood: !disabilities.disabilityFood,
                    })
                  }
                  onBlur={() => {
                    saveToCookie(disabilities, stepState.step);
                  }}
                >
                  <div>
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label={
                        <Trans i18nKey="steps:healthStep.label_no"></Trans>
                      }
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      data-cy="yes_have_allergies"
                      value={true}
                      control={<Radio color="primary" />}
                      label={
                        <Trans i18nKey="steps:healthStep.label_following"></Trans>
                      }
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {disabilities.disabilityFood && (
                <Grid item sm={8} xs={12} spacing={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    data-cy="food_allergy"
                    disabled={!disabilities.disabilityFood}
                    error={showErrors.disabilityFoodDetail ? true : false}
                    id="outlined-multiline-static"
                    label={
                      <Trans i18nKey="steps:healthStep.label_following_food_allergy"></Trans>
                    }
                    placeholder={t(
                      "steps:healthStep.placeholder_following_food_allergy"
                    )}
                    value={disabilities.disabilityFoodDetail}
                    onChange={(event) =>
                      setDisabilities({
                        ...disabilities,
                        disabilityFoodDetail: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(disabilities, stepState.step);
                    }}
                    helperText={
                      showErrors.disabilityFoodDetail
                        ? t("common:errors.incorrect_entry")
                        : ""
                    }
                    multiline
                    rows={2}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <p className="font-medium text-md mb-4 mt-6 text-grey-400">
                <Trans i18nKey="steps:healthStep.heading_drug_allergy"></Trans>
              </p>
            </Grid>
            <Grid container xs={12} className="justify-between">
              <Grid item sm={4} xs={12}>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={disabilities.disabilityMedAllergy}
                  onChange={() =>
                    setDisabilities({
                      ...disabilities,
                      disabilityMedAllergy: !disabilities.disabilityMedAllergy,
                    })
                  }
                  onSelect={() => {
                    saveToCookie(disabilities, stepState.step);
                  }}
                >
                  <div>
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label={
                        <Trans i18nKey="steps:healthStep.label_no"></Trans>
                      }
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      data-cy="yes_med_allergies"
                      value={true}
                      control={<Radio color="primary" />}
                      label={
                        <Trans i18nKey="steps:healthStep.label_following"></Trans>
                      }
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {disabilities.disabilityMedAllergy && (
                <Grid item sm={8} xs={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    data-cy="med_allergies_text_field"
                    error={showErrors.disabilityMedAllergyDetail ? true : false}
                    id="outlined-multiline-static"
                    label={
                      <Trans i18nKey="steps:healthStep.label_drug_allergy2"></Trans>
                    }
                    placeholder={t(
                      "steps:healthStep.placeholder_drug_allergy2"
                    )}
                    value={disabilities.disabilityMedAllergyDetail}
                    onChange={(event) =>
                      setDisabilities({
                        ...disabilities,
                        disabilityMedAllergyDetail: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(disabilities, stepState.step);
                    }}
                    helperText={
                      showErrors.disabilityMedAllergyDetail
                        ? t("common:errors.incorrect_entry")
                        : ""
                    }
                    multiline
                    rows={2}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container xs={12} className="mt-6">
            <Grid item xs={12}>
              <h2 className="blue-heading mt-8 mb-6">
                <Trans i18nKey="steps:healthStep.heading_support"></Trans>
              </h2>
              <p className="font-medium text-md mb-4 text-grey-400">
                <Trans i18nKey="steps:healthStep.text_support"></Trans>
              </p>
            </Grid>
            <Grid container xs={12} className="justify-between">
              <Grid item sm={4} xs={12}>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={disabilities.disabilityLocomotion}
                  onChange={() =>
                    setDisabilities({
                      ...disabilities,
                      disabilityLocomotion: !disabilities.disabilityLocomotion,
                    })
                  }
                  onSelect={() => {
                    saveToCookie(disabilities, stepState.step);
                  }}
                >
                  <div>
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label={
                        <Trans i18nKey="steps:healthStep.label_no"></Trans>
                      }
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      data-cy="yes_have_disability_locomotion"
                      value={true}
                      control={<Radio color="primary" />}
                      label={
                        <Trans i18nKey="steps:healthStep.label_following"></Trans>
                      }
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {disabilities.disabilityLocomotion && (
                <Grid item sm={8} xs={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    data-cy="disability_locomotion_text_field"
                    error={showErrors.disabilityLocomotionDetail ? true : false}
                    id="outlined-multiline-static"
                    label={
                      <Trans i18nKey="steps:healthStep.label_restrictions"></Trans>
                    }
                    placeholder={t("steps:healthStep.placeholder_restrictions")}
                    value={disabilities.disabilityLocomotionDetail}
                    onChange={(event) =>
                      setDisabilities({
                        ...disabilities,
                        disabilityLocomotionDetail: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(disabilities, stepState.step);
                    }}
                    helperText={
                      showErrors.disabilityLocomotionDetail
                        ? t("common:errors.incorrect_entry")
                        : ""
                    }
                    multiline
                    rows={2}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <p className="font-medium text-md mb-4 mt-6 text-grey-400">
                <Trans i18nKey="steps:healthStep.heading_drug_allergy_2"></Trans>
              </p>
            </Grid>
            <Grid container xs={12} className="justify-between">
              <Grid item sm={4} xs={12}>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={disabilities.disabilityHelp}
                  onChange={() =>
                    setDisabilities({
                      ...disabilities,
                      disabilityHelp: !disabilities.disabilityHelp,
                    })
                  }
                  onSelect={() => {
                    saveToCookie(disabilities, stepState.step);
                  }}
                >
                  <div>
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label={
                        <Trans i18nKey="steps:healthStep.label_no"></Trans>
                      }
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      data-cy="yes_need_disability_help"
                      value={true}
                      control={<Radio color="primary" />}
                      label={
                        <Trans i18nKey="steps:healthStep.label_following"></Trans>
                      }
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {disabilities.disabilityHelp && (
                <Grid item sm={8} xs={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    data-cy="disability_help_text_field"
                    disabled={!disabilities.disabilityHelp}
                    id="outlined-multiline-static"
                    label={
                      <Trans i18nKey="steps:healthStep.label_drug_allergy"></Trans>
                    }
                    placeholder={t("steps:healthStep.placeholder_drug_allergy")}
                    value={disabilities.disabilityHelpDetail}
                    onChange={(event) =>
                      setDisabilities({
                        ...disabilities,
                        disabilityHelpDetail: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(disabilities, stepState.step);
                    }}
                    error={showErrors.disabilityHelpDetail ? true : false}
                    helperText={
                      showErrors.disabilityHelpDetail
                        ? t("common:errors.incorrect_entry")
                        : ""
                    }
                    multiline
                    rows={2}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} className="mt-6">
            <Grid item xs={12}>
              <Grid item xs={12}>
                <h2 className="blue-heading mt-8 mb-6">
                  <Trans i18nKey="steps:healthStep.heading_drugs"></Trans>
                </h2>
              </Grid>
              <Grid item xs={12}>
                <p className="font-medium text-md mb-4 text-grey-400">
                  <Trans i18nKey="steps:healthStep.text_drugs"></Trans>
                </p>
              </Grid>
              <Grid item xs={12}>
                <FeedbackMessage
                  information
                  heading={<Trans i18nKey="steps:healthStep.example"></Trans>}
                  description={
                    <Trans i18nKey="steps:healthStep.drug_example_text"></Trans>
                  }
                />
              </Grid>
              <Grid container xs={12}>
                <RadioGroup
                  className="medication-checkboxes"
                  aria-label="gender"
                  name="gender1"
                  value={generalInfo.medicationGeneral}
                  onChange={() =>
                    setGeneralInfo({
                      ...generalInfo,
                      medicationGeneral: !generalInfo.medicationGeneral,
                    })
                  }
                  onBlur={() => {
                    saveToCookie(generalInfo, stepState.step);
                  }}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label={
                      <Trans i18nKey="steps:healthStep.label_do_not_avoid"></Trans>
                    }
                  />
                  <FormControlLabel
                    data-cy="following_drugs_intake"
                    value={true}
                    control={<Radio color="primary" />}
                    label={
                      <Trans i18nKey="steps:healthStep.label_follwing_drugs"></Trans>
                    }
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            {generalInfo.medicationGeneral &&
              generalInfo.medication.map((curr, index) => (
                <Grid
                  container
                  className="my-6 p-4 information-box"
                  xs={12}
                  key={curr.index}
                >
                  <Grid container xs={12} spacing={1}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        data-cy="drug_name"
                        error={showErrors.drugName ? true : false}
                        id="outlined-multiline-static"
                        label={
                          <Trans i18nKey="steps:healthStep.label_name_the_drug"></Trans>
                        }
                        placeholder={t(
                          "steps:healthStep.placeholder_drug_name"
                        )}
                        value={curr.name}
                        onChange={(event) =>
                          handleMedicationChange(event, curr, "name")
                        }
                        onBlur={() => {
                          saveToCookie(generalInfo, stepState.step);
                        }}
                        helperText={
                          showErrors.drugName
                            ? t("common:errors.incorrect_entry")
                            : ""
                        }
                        variant="filled"
                      />
                    </Grid>
                    <Grid item sm={5} xs={11} className="second__input">
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        data-cy="drug_type"
                        className={classes.select}
                        alignItems="flex-start"
                        alignContent="flex-start"
                        id="outlined-multiline-static"
                        select
                        error={showErrors.drugName ? true : false}
                        label={
                          <Trans i18nKey="steps:healthStep.label_type_of_drug"></Trans>
                        }
                        value={curr.agent}
                        onChange={(event) => {
                          handleMedicationChange(event, curr, "agent");
                          saveToCookie(generalInfo, stepState.step);
                        }}
                        variant="filled"
                      >
                        {medications.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      container
                      sm={1}
                      xs={1}
                      justify="flex-end"
                      alignItems="center"
                    >
                      <span
                        onClick={() => removeMedication(curr)}
                        className="size-lg"
                      >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.71494 8.09951C5.65607 7.51082 6.11836 7 6.70998 7H11H13H15H17H21.29C21.8816 7 22.3439 7.51082 22.2851 8.0995L20.7751 23.199C20.6729 24.2214 19.8125 25 18.785 25H9.21496C8.18746 25 7.32713 24.2214 7.22489 23.199L5.71494 8.09951Z"
                            stroke="#999995"
                            strokeWidth="2"
                          />
                          <path
                            d="M7 7L10 7L12 4L16 4L18 7L21 7"
                            stroke="#999995"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.0946 11.8956C11.4646 15.0196 11.6287 16.7716 11.8956 19.9056M16.8956 11.8956C16.5255 15.0196 16.3614 16.7716 16.0946 19.9056"
                            stroke="#999995"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container xs={12} className="pt-6" spacing={1}>
                    <Grid item md={3} sm={6} xs={6}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="outlined-multiline-static"
                        label={
                          <Trans i18nKey="steps:healthStep.label_morning_diagnosis"></Trans>
                        }
                        placeholder={t("steps:healthStep.placeholder_doses")}
                        fullWidth={true}
                        value={curr.dosage_morning}
                        onChange={(event) =>
                          handleMedicationChange(event, curr, "dosage_morning")
                        }
                        onBlur={() => {
                          saveToCookie(generalInfo, stepState.step);
                        }}
                        variant="filled"
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={6}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="outlined-multiline-static"
                        label={
                          <Trans i18nKey="steps:healthStep.label_afternoon_diagnosis"></Trans>
                        }
                        placeholder={t("steps:healthStep.placeholder_doses")}
                        fullWidth={true}
                        value={curr.dosage_noon}
                        onChange={(event) =>
                          handleMedicationChange(event, curr, "dosage_noon")
                        }
                        onBlur={() => {
                          saveToCookie(generalInfo, stepState.step);
                        }}
                        variant="filled"
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={6}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="outlined-multiline-static"
                        label={
                          <Trans i18nKey="steps:healthStep.label_evening_diagnosis"></Trans>
                        }
                        placeholder={t("steps:healthStep.placeholder_doses")}
                        fullWidth={true}
                        value={curr.dosage_evening}
                        onChange={(event) =>
                          handleMedicationChange(event, curr, "dosage_evening")
                        }
                        onBlur={() => {
                          saveToCookie(generalInfo, stepState.step);
                        }}
                        variant="filled"
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={6}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="outlined-multiline-static"
                        label={
                          <Trans i18nKey="steps:healthStep.label_night_diagnosis"></Trans>
                        }
                        placeholder={t("steps:healthStep.placeholder_doses")}
                        fullWidth={true}
                        value={curr.dosage_night}
                        onChange={(event) =>
                          handleMedicationChange(event, curr, "dosage_night")
                        }
                        onBlur={() => {
                          saveToCookie(generalInfo, stepState.step);
                        }}
                        variant="filled"
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            {generalInfo.medicationGeneral && (
              <Grid container direction="column" spacing={8}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    className="add-more-button"
                    data-cy="add-more-medication"
                    color="primary"
                    onClick={addMedication}
                  >
                    <Trans i18nKey="steps:healthStep.text_add_more_button"></Trans>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <p className="font-bold text-md mb-4 text-grey-400">
                    <Trans i18nKey="steps:healthStep.text_other_medication"></Trans>
                  </p>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="outlined-multiline-static"
                    value={generalInfo.otherMedication}
                    onChange={(event) =>
                      setGeneralInfo({
                        ...generalInfo,
                        otherMedication: event.target.value,
                      })
                    }
                    onBlur={() => {
                      saveToCookie(generalInfo, stepState.step);
                    }}
                    multiline
                    rows={7}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={12}>
              <h2 className="blue-heading mt-8 mb-6">
                <Trans i18nKey="steps:healthStep.heading_miscellaneous"></Trans>
              </h2>
              <p className="font-medium text-md mb-4 text-grey-400">
                <Trans i18nKey="steps:healthStep.text_miscellaneous"></Trans>
              </p>
            </Grid>
            <Grid container xs={12}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                id="outlined-multiline-static"
                value={generalInfo.miscellaneous}
                onChange={(event) =>
                  setGeneralInfo({
                    ...generalInfo,
                    miscellaneous: event.target.value,
                  })
                }
                onBlur={() => {
                  saveToCookie(generalInfo, stepState.step);
                }}
                multiline
                rows={7}
                fullWidth={true}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container className="pt-6 buttons_bar">
            {toggleError && (
              <Grid item xs={12}>
                <p className="font-medium text-md mb-4 text-gray-600 flex justify-end">
                  <Trans i18nKey="steps:personalInformation.validation_error_message"></Trans>
                </p>
              </Grid>
            )}
            <Grid item xs={12}>
              <p className="mt-2">
                <Trans i18nKey="steps:general.mandatory"></Trans>
              </p>
            </Grid>
            <Grid item xs={6} className="mt-6">
              <Button
                variant="contained"
                size="large"
                color="default"
                onClick={stepBackHandler}
              >
                <Trans i18nKey="steps:personalInformation.previous"></Trans>
              </Button>
            </Grid>
            <Grid xs={6} className="mt-6" container justify="flex-end">
              <Button
                data-cy="submit"
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                onClick={() => scrollToTopHandler()}
              >
                <Trans i18nKey="steps:visitStep.button_next"></Trans>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default HealthStep;

import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";
import { FeedbackMessage } from "../../components/FeedbackMessage";
import { scrollToTopHandler } from "../util/scrollToTopHandler";
import RadioWithText from "../../components/RadioWithText";
import { useEffect } from "react";
import { saveToCookie } from "../util/cookiesHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  radioGroup: {
    flexDirection: "row",
    margin: 0,
  },
  radioButton: {
    marginLeft: -theme.spacing(1),
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  input: {
    margin: theme.spacing(1),
    width: "100%",
    "& .MuiFormLabel-root": {
      fontStyle: "italic",
      fontWeight: "bold",
    },
  },
  multiline: {
    width: "100%",
  },
}));

const extractLegalInfo = (stepState) => {
  return {
    step: stepState.step,
    referrer: stepState.referrer,
    referrerDetail: stepState.referrerDetail,
    legalAGB: stepState.legalAGB,
    legalPrivacy: stepState.legalPrivacy,
    omi_id: stepState.omi_id,
  };
};

function Closing({ stepState, onSubmit, onGoBack }) {
  const classes = useStyles();
  const { t } = useTranslation(["translation", "common"]);

  const [legalInfo, setLegalInfo] = useState(extractLegalInfo(stepState));
  const [toggleError, setToggleError] = useState(false);
  const [showErrors, setShowErrors] = useState({
    referrer: false,
    legalPrivacy: false,
  });

  const urlParams = new URLSearchParams(document.location.search);
  const omi_id = urlParams.get("omi_id");

  useEffect(() => {
    if (omi_id) {
      setLegalInfo((state) => ({
        ...state,
        omi_id: omi_id,
      }));
    }
  }, [legalInfo.omi_id, omi_id]);

  useEffect(() => {
    saveToCookie(legalInfo, stepState.step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalInfo]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateFormHandler(legalInfo)) {
      return;
    }

    onSubmit({ ...legalInfo });
  };

  const handleRadioChange = (event) => {
    setLegalInfo((state) => ({
      ...state,
      referrer: event.target.value,
      referrerDetail:
        event.target.value === "no specification" ? "no specification" : "",
    }));
  };

  const stepBackHandler = (event) => {
    event.preventDefault();

    onGoBack({ ...legalInfo });
  };

  const validateFormHandler = (formState) => {
    let error = { ...showErrors };
    let validationPassed = true;

    const validateField = (key) => {
      if (!formState[key]) {
        error[key] = true;
        validationPassed = false;
      } else {
        error[key] = false;
      }
    };

    ["referrer", "legalPrivacy"].map((key) => validateField(key));
    setShowErrors((state) => ({
      ...state,
      ...error,
    }));
    !validationPassed ? setToggleError(true) : setShowErrors(false);
    return validationPassed;
  };

  return (
    <div className={classes.root}>
      <div id="feedback-message"></div>
      <div className="md:hidden mb-12 flex flex-col items-center">
        <button className="rounded-md px-2 border-solid border-blue-500 justify-center border text-blue-500">
          <Trans i18nKey="common:headings.closing_mobile">
            ( 5 / 6 ) Closing
          </Trans>
        </button>
      </div>
      {toggleError && (
        <FeedbackMessage
          error
          heading={
            <Trans i18nKey="common:errors.error_message_heading"></Trans>
          }
          description={
            <Trans i18nKey="common:errors.error_message_description"></Trans>
          }
        />
      )}
      <form action="#" onSubmit={handleSubmit} noValidate>
        <p className="grey-heading mb-4">
          <Trans i18nKey="steps:closingStep.stepTopic"></Trans>
        </p>
        <p className="font-medium text-4xl text-gray-700 mb-4">
          <Trans i18nKey="steps:closingStep.stepGreeting"></Trans>
        </p>
        <h2 className="blue-heading mt-8 mb-6">
          <Trans i18nKey="steps:closingStep.heading_recommendation"></Trans>
        </h2>
        <p
          className={`${
            showErrors.referrer && `text-red-500`
          } font-medium w-3/4 my-3 text-md mb-4 text-gray-600`}
        >
          <Trans i18nKey="steps:closingStep.text_recommendation"></Trans>
        </p>

        <RadioGroup
          className="w-full flex flex-col justify-between"
          aria-label="quiz"
          name="quiz"
          value={legalInfo.referrer}
          onChange={handleRadioChange}
        >
          <div>
            <FormControlLabel
              value="doctor"
              control={<Radio color="primary" />}
              label={t("steps:closingStep.label_doctor")}
            />
          </div>
          {legalInfo.referrer === "doctor" && (
            <div className="dropdown-input">
              <Grid item xs={10} sm={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="outlined-multiline-static"
                  label={
                    <Trans i18nKey="steps:closingStep.label_doctor_detail"></Trans>
                  }
                  placeholder={t("steps:closingStep.placeholder_doctor_detail")}
                  value={
                    legalInfo.referrer === "doctor"
                      ? legalInfo.referrerDetail
                      : ""
                  }
                  onChange={(event) =>
                    setLegalInfo({
                      ...legalInfo,
                      referrerDetail: event.target.value,
                    })
                  }
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </div>
          )}
          <div>
            <FormControlLabel
              data-cy="other_patient"
              value="other-Patient"
              control={<Radio color="primary" />}
              label={t("steps:closingStep.label_other_patient")}
            />
          </div>
          {legalInfo.referrer === "other-Patient" && (
            <div className="dropdown-input">
              <Grid item xs={10} sm={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  data-cy="other_patient_name"
                  id="outlined-multiline-static"
                  label={
                    <Trans i18nKey="steps:closingStep.label_doctor_detail"></Trans>
                  }
                  placeholder={t("steps:closingStep.placeholder_other_patient")}
                  value={
                    legalInfo.referrer === "other-Patient"
                      ? legalInfo.referrerDetail
                      : ""
                  }
                  onChange={(event) =>
                    setLegalInfo({
                      ...legalInfo,
                      referrerDetail: event.target.value,
                    })
                  }
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </div>
          )}
          <div>
            <FormControlLabel
              value="family"
              control={<Radio color="primary" />}
              label={t("steps:closingStep.label_family")}
            />
          </div>
          {legalInfo.referrer === "family" && (
            <div className="dropdown-input">
              <Grid item xs={10} sm={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="outlined-multiline-static"
                  label={
                    <Trans i18nKey="steps:closingStep.label_doctor_detail"></Trans>
                  }
                  placeholder={t("steps:closingStep.label_family")}
                  value={
                    legalInfo.referrer === "family"
                      ? legalInfo.referrerDetail
                      : ""
                  }
                  onChange={(event) =>
                    setLegalInfo({
                      ...legalInfo,
                      referrerDetail: event.target.value,
                    })
                  }
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </div>
          )}
          <div>
            <FormControlLabel
              value="friend"
              control={<Radio color="primary" />}
              label={t("steps:closingStep.label_friend")}
            />
          </div>
          {legalInfo.referrer === "friend" && (
            <div className="dropdown-input">
              <Grid item xs={10} sm={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="outlined-multiline-static"
                  label={
                    <Trans i18nKey="steps:closingStep.label_doctor_detail"></Trans>
                  }
                  placeholder={t("steps:closingStep.label_friend")}
                  value={
                    legalInfo.referrer === "friend"
                      ? legalInfo.referrerDetail
                      : ""
                  }
                  onChange={(event) =>
                    setLegalInfo({
                      ...legalInfo,
                      referrerDetail: event.target.value,
                    })
                  }
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </div>
          )}
          <div>
            <FormControlLabel
              value="media"
              control={<Radio color="primary" />}
              label={t("steps:closingStep.label_media")}
            />
          </div>
          {legalInfo.referrer === "media" && (
            <div className="dropdown-input">
              <Grid item xs={10} sm={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="outlined-multiline-static"
                  label={
                    <Trans i18nKey="steps:closingStep.label_media_detail"></Trans>
                  }
                  placeholder={t("steps:closingStep.placeholder_media_detail")}
                  value={
                    legalInfo.referrer === "media"
                      ? legalInfo.referrerDetail
                      : ""
                  }
                  onChange={(event) =>
                    setLegalInfo({
                      ...legalInfo,
                      referrerDetail: event.target.value,
                    })
                  }
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </div>
          )}
          <div>
            <FormControlLabel
              onClick={() =>
                setLegalInfo({
                  ...legalInfo,
                  referrerDetail: "no specification",
                })
              }
              error
              value="Other"
              control={<Radio color="primary" />}
              label={t("steps:closingStep.label_other")}
            />
          </div>
          {legalInfo.referrer === "Other" && (
            <div className="dropdown-input">
              <Grid item xs={10} sm={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="outlined-multiline-static"
                  label={
                    <Trans i18nKey="steps:closingStep.label_other_detail"></Trans>
                  }
                  placeholder={t("steps:closingStep.placeholder_other_detail")}
                  value={
                    legalInfo.referrer === "Other"
                      ? legalInfo.referrerDetail
                      : ""
                  }
                  onChange={(event) =>
                    setLegalInfo({
                      ...legalInfo,
                      referrerDetail: event.target.value,
                    })
                  }
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </div>
          )}
          <div>
            <FormControlLabel
              error
              value="no specification"
              control={<Radio color="primary" />}
              label={t("steps:healthStep.label_no_specification")}
            />
          </div>
        </RadioGroup>
        <div>
          <FeedbackMessage
            information
            heading={<Trans i18nKey="steps:closingStep.termsOfPayment" />}
            description={
              <Trans
                i18nKey="steps:closingStep.termsOfPayment_text"
                components={{ TextBold: <b></b> }}
              />
            }
          />

          <RadioWithText
            checkedInfo={legalInfo.legalPrivacy}
            setCheckedInfo={(event) =>
              setLegalInfo({
                ...legalInfo,
                legalPrivacy: event.target.value === "true" ? true : false,
              })
            }
            showErrors={showErrors.legalPrivacy}
            cypressClass="legal_privacy"
            ariaLabel="zahlungsbedingungen"
            name="zahlungsbedingungen"
            label={<Trans i18nKey="steps:closingStep.termsOfPayment" />}
            headline={
              <Trans
                i18nKey="steps:closingStep.termsOfPayment_checkboxText"
                components={{ TextBold: <b></b> }}
              />
            }
            labelOption1={<Trans i18nKey="steps:visitStep.label_yes"></Trans>}
            labelOption2={<Trans i18nKey="steps:visitStep.label_no"></Trans>}
          />

          <Grid container className="pt-6 buttons_bar">
            {toggleError && (
              <Grid item xs={12}>
                <p className="font-medium text-md mb-4 text-gray-600 flex justify-end">
                  <Trans i18nKey="steps:personalInformation.validation_error_message"></Trans>
                </p>
              </Grid>
            )}
            <Grid item xs={12}>
              <p className="mt-2">
                <Trans i18nKey="steps:general.mandatory"></Trans>
              </p>
            </Grid>
            <Grid item xs={6} className="mt-6">
              <Button
                variant="contained"
                size="large"
                color="default"
                onClick={stepBackHandler}
              >
                <Trans i18nKey="steps:personalInformation.previous"></Trans>
              </Button>
            </Grid>
            <Grid xs={6} container justify="flex-end" className="mt-6">
              <Button
                data-cy="submit"
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                onClick={() => scrollToTopHandler()}
              >
                <Trans i18nKey="steps:visitStep.button_next" />
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
}

export default Closing;

import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Link } from "@material-ui/core";
import { FeedbackMessage } from "../../components/FeedbackMessage";

import { scrollToTopHandler } from "../util/scrollToTopHandler";
import RadioWithText from "../../components/RadioWithText";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const extractPrivacyInfo = (stepState) => {
  return {
    step: stepState.step,
    legalContinuity: stepState.legalContinuity,
    legalNewsletter: stepState.legalNewsletter,
    chosenLanguage: stepState.chosenLanguage,
  };
};

function PrivacyStep({ stepState, onSubmit, onGoBack }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation(["translation", "common"]);
  const [privacyInfo, setPrivacyInfo] = useState(extractPrivacyInfo(stepState));
  const [toggleError, setToggleError] = useState(false);
  const [showErrors, setShowErrors] = useState({
    // just if we need error handling
    // legalNewsletter: false,
    // legalConfidentiality: false,
  });

  useEffect(() => {
    stepState.chosenLanguage = i18n.language;
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateFormHandler(privacyInfo)) {
      return;
    }

    onSubmit({ ...privacyInfo });
  };

  const stepBackHandler = (event) => {
    event.preventDefault();
    onGoBack({ ...privacyInfo });
  };

  const validateFormHandler = (formState) => {
    let error = { ...showErrors };
    let validationPassed = true;

    const validateField = (key) => {
      if (!formState[key]) {
        error[key] = true;
        validationPassed = false;
      } else {
        error[key] = false;
      }
    };

    [
      // Just if we need error handling
      // "legalNewsletter",
      // "legalConfidentiality",
    ].map((key) => validateField(key));
    setShowErrors((state) => ({
      ...state,
      ...error,
    }));
    !validationPassed ? setToggleError(true) : setShowErrors(false);
    return validationPassed;
  };

  return (
    <div className={classes.root}>
      <div id="feedback-message"></div>
      <div className="md:hidden mb-12 flex flex-col items-center">
        <button className="rounded-md px-2 border-solid border-blue-500 justify-center border text-blue-500">
          <Trans i18nKey="common:headings.closing_mobile">
            ( 6 / 6 ) Privacy
          </Trans>
        </button>
      </div>
      {toggleError && (
        <FeedbackMessage
          error
          heading={
            <Trans i18nKey="common:errors.error_message_heading"></Trans>
          }
          description={
            <Trans i18nKey="common:errors.error_message_description"></Trans>
          }
        />
      )}
      <form action="#" onSubmit={handleSubmit} noValidate>
        <p className="blue-heading mt-8 mb-6">
          <Trans i18nKey="steps:privacyStep.stepTopic"></Trans>
        </p>
        <p className="font-medium text-4xl text-gray-700 mb-4">
          <Trans i18nKey="steps:privacyStep.stepGreeting"></Trans>
        </p>
        <p className="font-medium text-base text-gray-500">
          <Trans
            i18nKey="steps:privacyStep.declarationOfConsent"
            components={{
              privacyLink: <Link target="_blank" />,
              TextBold: <b></b>,
            }}
          ></Trans>
        </p>

        <Grid item xs={12}>
          <FeedbackMessage
            information
            heading={<Trans i18nKey="steps:personalInformation.hint" />}
            description={
              <Trans
                i18nKey="steps:privacyStep.consentBox_text"
                components={{ privacyLink: <Link target="_blank" /> }}
                values={{ mail: "datenschutz@buchinger-wilhelmi.com" }}
              />
            }
          />
        </Grid>

        <p className="font-bold mt-8 text-lg text-gray-700">
          <Trans i18nKey="steps:privacyStep.stepReason4" />
        </p>

        <RadioWithText
          checkedInfo={privacyInfo.legalContinuity}
          setCheckedInfo={(event) =>
            setPrivacyInfo({
              ...privacyInfo,
              legalContinuity: event.target.value === "true" ? true : false,
            })
          }
          ariaLabel="kontinuität"
          name="kontinuität"
          text={
            <Trans
              i18nKey="steps:privacyStep.continuity_text"
              components={{
                privacyLink: <Link target="_blank" />,
                TextBold: <b></b>,
              }}
            />
          }
          labelOption1={<Trans i18nKey="steps:visitStep.label_yes"></Trans>}
          labelOption2={<Trans i18nKey="steps:visitStep.label_no"></Trans>}
        />

        <p className="font-bold mt-8 text-lg text-gray-700">
          <Trans i18nKey="steps:privacyStep.stepReason5" />
        </p>
        <RadioWithText
          checkedInfo={privacyInfo.legalNewsletter}
          setCheckedInfo={(event) =>
            setPrivacyInfo({
              ...privacyInfo,
              legalNewsletter: event.target.value === "true" ? true : false,
            })
          }
          ariaLabel="newsletter"
          name="newsletter"
          text={
            <Trans
              i18nKey="steps:privacyStep.newsletter_text"
              components={{
                privacyLink: <Link target="_blank" />,
                TextBold: <b></b>,
              }}
            />
          }
          labelOption1={<Trans i18nKey="steps:visitStep.label_yes"></Trans>}
          labelOption2={<Trans i18nKey="steps:visitStep.label_no"></Trans>}
        />

        <Grid container className="pt-6 buttons_bar">
          {toggleError && (
            <Grid item xs={12}>
              <p className="font-medium text-md mb-4 text-gray-600 flex justify-end">
                <Trans i18nKey="steps:personalInformation.validation_error_message"></Trans>
              </p>
            </Grid>
          )}
          <Grid item xs={12}></Grid>
          <Grid item xs={6} className="mt-6">
            <Button
              variant="contained"
              size="large"
              color="default"
              onClick={stepBackHandler}
            >
              <Trans i18nKey="steps:personalInformation.previous"></Trans>
            </Button>
          </Grid>
          <Grid xs={6} container justify="flex-end" className="mt-6">
            <Button
              data-cy="submit"
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              onClick={() => scrollToTopHandler()}
            >
              <Trans i18nKey="steps:personalInformation.label_submit_form" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default PrivacyStep;

import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ["common", "steps"],
    backend: {
      loadPath:
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? "locales/{{lng}}/{{ns}}.json"
          : "https://buchingerwilhelmiapp.github.io/bw-contactform-translations/marbella/locales/{{lng}}/{{ns}}.json",
    },
    fallbackLng: ["de", "en", "es", "fr"],
    load:['de', 'en', 'es', 'fr'],
    whitelist:['de', 'de-DE', 'en', 'en-US', 'es', 'es-ES', 'fr', 'fr-FR',],
    defaultlng: 'de',
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      cookieMinutes: 10,
      cookieDomain: 'myDomain',

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: '/', sameSite: 'strict' }
    },
    debug: true,
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // transSupportBasicHtmlNodes: "true",
  });

export default i18n;

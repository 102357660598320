const ENDPOINT = "https://bwcontactformbackend.bw-clinics.com/idealsubmission";

const service = (formData) => {
  return fetch(ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });
};

export default service;
